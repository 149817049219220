import Swiper from 'swiper'
import '~node_modules/swiper/swiper-bundle.css'

export const sliders = []

const sliderContainers = document.querySelectorAll('.js-slider')

if (sliderContainers.length) {
	sliderContainers.forEach(container => {
		const slider = container.querySelector('.swiper-container')
		const paging = container.querySelector('.js-paging')
		let prev
		let next

		if (paging) {
			prev = paging.querySelector('.js-paging-prev')
			next = paging.querySelector('.js-paging-next')
		}

		const swiper = new Swiper (slider, {
			loop: false, 
			speed: 1000,
			slidesPerView: 1,
			direction: 'horizontal',
			breakpoints: {
				640: {
					slidesPerView: 2,
					freeMode: true
				}
			},
		})

		sliders.push(swiper)

		if (next) {
			next.addEventListener('click', () => {
				swiper.slideNext(1000)
			})
		}
		if (prev) {
			prev.addEventListener('click', () => {
				swiper.slidePrev(1000)
			})
		}

		// Used for animations on slider dragging
		swiper.on('touchMove', ({el}) => {
			el.classList.add('dragged')
		})
		swiper.on('touchEnd', ({el}) => {
			el.classList.remove('dragged')
		})
	})
}
